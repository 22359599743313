import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import Button from 'components/Core/Button';
import HyperLink from 'components/Core/HyperLink';
import EmbedForm from 'components/Tools/EmbeddableForm';
import EmbedLink from 'components/Tools/EmbedLink';
import NumberInputField from 'components/Core/NumberInputField';
import GatedSubscribeForm from 'components/Tools/GatedSubscribeForm';
import useBreakpointView from 'utils/useBreakpointView';
import { getFormValid, blurFocusAndScroll, checkFormFieldValidate } from 'utils/formUtils';
import { FormContainer } from '../../styles';

const HvacCFMForm = ({ gatedForm, ...otherProps }) => {
  const [gateFormCookieIncrease, setGateFormCookieIncrease] = useState(0);
  const [roomArea, setRoomArea] = useState(0);
  const [cfmResult, setCFMResult] = useState(0);
  const resultRef = useRef(null);
  const isMobile = useBreakpointView(['xs', 'sm']);

  const calcCFM = (values) => {
    const result =
      (values.room_width * values.room_height * values.room_length * values.air_changes_per_hour) /
      60;
    setRoomArea(values.room_width * values.room_length);
    setCFMResult(result);
    setGateFormCookieIncrease(gateFormCookieIncrease + 1);
  };

  const formik = useFormik({
    initialValues: {
      room_length: 0,
      room_width: 0,
      room_height: 0,
      air_changes_per_hour: 0,
    },
    validate: (values) => {
      return {
        ...(!checkFormFieldValidate(values.room_length) && {
          room_length: 'Room Length is invalid',
        }),
        ...(!checkFormFieldValidate(values.room_width) && { room_width: 'Room Width is invalid' }),
        ...(!checkFormFieldValidate(values.room_height) && {
          room_height: 'Room Height is invalid',
        }),
        ...(!checkFormFieldValidate(values.air_changes_per_hour) && {
          air_changes_per_hour: 'Air changes per hour is invalid',
        }),
      };
    },
    onSubmit: (values) => {
      blurFocusAndScroll(resultRef, isMobile);
      calcCFM(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const formValid = getFormValid({ ...formik });

  return (
    <FormContainer {...otherProps}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container">
          <div className="p-[20px] bg-white shadow-[0_15px_40px_rgba(0,0,0,0.1)] flex flex-col md:p-[40px]">
            <div className="flex flex-col md:flex-row">
              <div className="flex-1 py-0 px-0 md:px-[28px] mb-[20px] md:mb-0">
                <GatedSubscribeForm gatedForm={gatedForm} increaseCookie={gateFormCookieIncrease}>
                  <div className="mb-[5px]">
                    <NumberInputField
                      id="room_length"
                      label="Room length (ft.)"
                      min={1}
                      max={99999}
                      values={formik.values}
                      formValid={formValid}
                      handleChange={onHandleChange}
                    />
                  </div>
                  <div className="mb-[5px]">
                    <NumberInputField
                      id="room_width"
                      label="Room width (ft.)"
                      min={1}
                      max={99999}
                      values={formik.values}
                      formValid={formValid}
                      handleChange={onHandleChange}
                    />
                  </div>
                  <div className="mb-[5px]">
                    <NumberInputField
                      id="room_height"
                      label="Room height (ft.)"
                      min={1}
                      max={99999}
                      values={formik.values}
                      formValid={formValid}
                      handleChange={onHandleChange}
                    />
                  </div>
                  <div className="mb-[5px]">
                    <NumberInputField
                      id="air_changes_per_hour"
                      label="Air changes per hour  (ACH)"
                      min={1}
                      max={99999}
                      values={formik.values}
                      formValid={formValid}
                      handleChange={onHandleChange}
                    />
                  </div>
                  <Button shadow={false} onClick={() => formik.submitForm()} className="!w-full">
                    Calculate
                  </Button>
                  <EmbedLink
                    show={!otherProps.isEmbed}
                    openCopyEmbedLinkDialog={otherProps?.openCopyEmbedLinkDialog}
                    classNames="!hidden md:!flex items-center justify-center mt-[20px] text-[16px]"
                  />
                </GatedSubscribeForm>
              </div>
              <div
                ref={resultRef}
                className="flex-1 p-[30px] bg-[var(--grey-2)] flex flex-col items-center justify-start h-auto gap-[20px]"
              >
                <h4 className="text-[16px] font-black text-[var(--grey-8)] mb-0">RESULTS</h4>
                <div className="flex flex-col items-center">
                  <p className="text-[20px] font-['Nunito Sans'] text-black mb-0">Room area:</p>
                  <p className="text-[25px] text-[var(--darkest)] mb-0 mt-[10px]">
                    {roomArea.toLocaleString()}
                    &nbsp;Sqft
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <p className="text-[20px] font-['Nunito Sans'] font-bold text-black mb-0">
                    Required CFM:
                  </p>
                  <h4 className="text-[28px] font-extrabold text-[var(--darkest)] mb-0 mt-[10px]">
                    {cfmResult.toLocaleString()}
                    &nbsp;CFM
                  </h4>
                </div>
                <p className="mb-0 mt-[30px] text-center">
                  Learn how we calculated this result{' '}
                  <HyperLink href="#section-tool-page-content--container">below</HyperLink>.
                </p>
              </div>
            </div>
            <EmbedLink
              show={!otherProps.isEmbed}
              openCopyEmbedLinkDialog={otherProps?.openCopyEmbedLinkDialog}
              classNames="!flex md:!hidden items-center justify-center mt-[20px]"
            />
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

const HvacFCMCalcForm = EmbedForm(HvacCFMForm);
export default HvacFCMCalcForm;
